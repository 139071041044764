import { useLocation } from 'react-router-dom';
import LocaleContext from '../context/LocaleContext';

// eslint-disable-next-line react/prop-types
const LocaleProvider = ({ children }) => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const locale = pathParts[1] === 'en' || pathParts[1] === 'jp' ? pathParts[1] : 'en';

  return (
    <LocaleContext.Provider value={locale}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
