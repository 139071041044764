import { useEffect, useState, useContext } from 'react';
import { motion } from 'framer-motion';
import imagesLoaded from 'imagesloaded';
import { Link, useNavigate } from 'react-router-dom';
import { pageTransition } from '../helpers/animation';
import showHeader from '../helpers/header';
import ContentfulContext from './context/ContentfulContext';
import LocaleContext from './context/LocaleContext';
import Caret from './icons/Caret';
import Close from './icons/Close';
import Filter from './icons/Filter';
import translations from '../helpers/translations.json';

// eslint-disable-next-line react/prop-types
const Gallery = ({ location }) => {
  // eslint-disable-next-line react/prop-types
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const allWorks = useContext(ContentfulContext);
  const locale = useContext(LocaleContext);
  const currentCollectionParams = params.getAll('collection').map(decodeURIComponent);
  const currentCategoryParams = params.getAll('category');

  const [filteredWorks, setFilteredWorks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState(currentCollectionParams);
  const [selectedCategories, setSelectedCategories] = useState(currentCategoryParams);
  const [loaded, setLoaded] = useState(false);
  const [isCategoriesDropdownOpen, setIsCategoriesDropdownOpen] = useState(false);
  const [isCollectionsDropdownOpen, setIsCollectionsDropdownOpen] = useState(false);
  const [isCategoryMobileMenuOpen, setIsCategoryMobileMenuOpen] = useState(false);
  const [isCollectionsMobileMenuOpen, setIsCollectionsMobileMenuOpen] = useState(false);
  const [isMobileFilterMenuOpen, setIsMobileFilterMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 500);

  const filterWorks = works => {
    setFilteredWorks(
      works.filter(
        work => (!selectedCategories.length || selectedCategories.includes(work.category))
        && (!selectedCollections.length || selectedCollections.includes(work.collection))
      ).sort((a, b) => {
        if (a.category < b.category) return -1;
        if (a.category > b.category) return 1;

        return b.title - a.title;
      })
    );
  };

  const updateURL = () => {
    if (selectedCategories.length > 0) {
      params.delete('category');
      selectedCategories.forEach(category => {
        params.append('category', category);
      });
    } else {
      params.delete('category');
    }

    if (selectedCollections.length > 0) {
      params.delete('collection');
      selectedCollections.forEach(collection => {
        params.append('collection', encodeURIComponent(collection));
      });
    } else {
      params.delete('collection');
    }

    const queryString = params.toString();

    navigate(`/${locale ? `${locale}/` : ''}gallery${queryString ? `?${queryString}` : ''}`);
  };

  const updateSelection = (event, setSelection) => {
    const { value, checked } = event.target;

    setSelection(prev => {
      if (checked) {
        return [...prev, value];
      }
      return prev.filter(item => item !== value);
    });
  };

  const updateSelectedCollections = event => {
    updateSelection(event, setSelectedCollections);
  };

  const updateSelectedCategories = event => {
    updateSelection(event, setSelectedCategories);
  };

  const clearAll = () => {
    setSelectedCategories([]);
    setSelectedCollections([]);

    navigate(`${locale ? `/${locale}` : ''}/gallery`);
  };

  const removeFilter = event => {
    const filterValue = event.target.value;

    if (selectedCategories.includes(filterValue)) {
      setSelectedCategories(selectedCategories.filter(element => element !== filterValue));

      params.delete('category', filterValue);
    } else if (selectedCollections.includes(filterValue)) {
      setSelectedCollections(selectedCollections.filter(element => element !== filterValue));

      params.delete('collection', encodeURIComponent(filterValue));
    }

    const newParameter = params.toString();

    if (newParameter.length > 0) {
      navigate(`/${locale ? `${locale}/` : ''}gallery/?${newParameter.toString()}`);
    } else {
      navigate(`/${locale ? `${locale}/` : ''}gallery`);
    }
  };

  const categoryFilterMenu = () => (
    <>
      <ul>
        {categories.map(category => {
          const categoryId = category.toLowerCase();
          const isChecked = selectedCategories.includes(category);

          return (
            <li key={categoryId}>
              <input
                type="checkbox"
                id={categoryId}
                name={categoryId}
                value={category}
                checked={isChecked}
                onChange={updateSelectedCategories}
              />
              <label htmlFor={categoryId}>
                {locale === 'en' ? category : translations.filter.categories[category]}
              </label>
            </li>
          );
        })}
      </ul>
      <div className="buttons">
        <button
          type="submit"
          onClick={() => {
            setIsCategoriesDropdownOpen(false);
            setIsMobileFilterMenuOpen(false);
            setIsCategoryMobileMenuOpen(false);
            updateURL();
          }}
        >
          {locale === 'en' ? 'Apply' : '適用'}

        </button>
        <button
          type="submit"
          onClick={() => {
            setSelectedCategories([]);
            setIsCategoriesDropdownOpen(false);
            params.delete('category');

            navigate(`/${locale ? `${locale}/` : ''}gallery?${params.toString()}`);
          }}
        >
          {locale === 'en' ? 'Clear' : 'クリア'}
        </button>
      </div>
    </>
  );

  const collectionsFilterMenu = () => (
    <>
      <ul>
        {collections.map(collection => {
          const collectionId = collection.toLowerCase().split(' ').join('_');
          const isChecked = selectedCollections.includes(collection);

          if (collection === 'N/A') return '';

          return (
            <li key={collectionId}>
              <input
                type="checkbox"
                id={collectionId}
                name={collectionId}
                value={collection}
                checked={isChecked}
                onChange={updateSelectedCollections}
              />
              <label htmlFor={collectionId}>
                {locale === 'en' ? collection : translations.filter.collections[collection]}
              </label>
            </li>
          );
        })}
      </ul>
      <div className="buttons">
        <button
          type="submit"
          onClick={() => {
            updateURL();
            setIsCollectionsDropdownOpen(false);
            setIsCollectionsMobileMenuOpen(false);
            setIsMobileFilterMenuOpen(false);
          }}
          className="submit-button"
        >
          {locale === 'en' ? 'Apply' : '適用'}
        </button>
        <button
          type="submit"
          onClick={() => {
            setSelectedCollections([]);
            setIsCollectionsDropdownOpen(false);
            params.delete('collection');

            navigate(`/${locale ? `${locale}/` : ''}gallery?${params.toString()}`);
          }}
        >
          {locale === 'en' ? 'Clear' : 'クリア'}
        </button>
      </div>
    </>
  );

  const mobileFilterPopupHeader = () => {
    if (isCategoryMobileMenuOpen) {
      return (
        <>
          <button
            type="button"
            className="back-button"
            onClick={() => {
              setIsCategoryMobileMenuOpen(false);
            }}
          >
            <Caret />
          </button>
          <p>{locale === 'en' ? 'Category' : 'カテゴリー'}</p>
          <button
            type="button"
            className="close-button"
            onClick={() => {
              setIsMobileFilterMenuOpen(false);
              setIsCategoryMobileMenuOpen(false);
            }}
          >
            <Close />
          </button>
        </>
      );
    } if (isCollectionsMobileMenuOpen) {
      return (
        <>
          <button
            type="button"
            className="back-button"
            onClick={() => {
              setIsCollectionsMobileMenuOpen(false);
            }}
          >
            <Caret />
          </button>
          <p>{locale === 'en' ? 'Collections' : 'コレクション'}</p>
          <button
            type="button"
            className="close-button"
            onClick={() => {
              setIsMobileFilterMenuOpen(false);
              setIsCollectionsMobileMenuOpen(false);
            }}
          >
            <Close />
          </button>
        </>
      );
    }

    return (
      <>
        <p>{locale === 'en' ? 'Filters' : 'フィルター'}</p>
        <button
          type="button"
          className="close-button"
          onClick={() => {
            setIsMobileFilterMenuOpen(false);
          }}
        >
          <Close />
        </button>
      </>
    );
  };

  const mobileFilterPopupMenuContent = () => {
    if (isCategoryMobileMenuOpen) {
      return categoryFilterMenu();
    }
    if (isCollectionsMobileMenuOpen) {
      return collectionsFilterMenu();
    }

    return (
      <>
        <ul>
          <li>
            <button type="button" onClick={() => setIsCategoryMobileMenuOpen(true)}>
              {locale === 'en' ? 'Categories' : 'カテゴリー'}
              {currentCategoryParams.length > 0 ? `・${currentCategoryParams.length}` : ''}
              <Caret />
            </button>
          </li>
          <li>
            <button type="button" onClick={() => setIsCollectionsMobileMenuOpen(true)}>
              {locale === 'en' ? 'Collections' : 'コレクション'}
              {currentCollectionParams.length > 0 ? `・${currentCollectionParams.length}` : ''}
              <Caret />
            </button>
          </li>
        </ul>
        <div className="clear-all-button-container">
          <button
            onClick={clearAll}
            type="button"
            className="clear-all-button"
          >
            {locale === 'en' ? 'Clear All' : 'すべてクリア'}
          </button>
        </div>
      </>
    );
  };

  useEffect(() => {
    setCollections(Array.from(new Set(allWorks.map(obj => obj.collection).filter(Boolean))));
    setCategories(Array.from(new Set(allWorks.map(obj => obj.category).filter(Boolean))));

    filterWorks(allWorks);
  }, [allWorks, location]);

  useEffect(() => {
    let totalImagesLoaded = 0;

    filteredWorks
      .map(work => document.querySelector(`.thumbnail[data-key="${work.slug}"]`))
      .forEach(item => {
        imagesLoaded(item, { background: true }, () => {
          totalImagesLoaded += 1;
          if (totalImagesLoaded === filteredWorks.length) {
            showHeader();
            setLoaded(true);

            // eslint-disable-next-line react/prop-types
            const lastClickedElement = document.getElementById(location.state?.workId);

            if (lastClickedElement !== null) {
              lastClickedElement.scrollIntoView();

              window.addEventListener('beforeunload', () => {
                // eslint-disable-next-line react/prop-types
                navigate(location.pathname, { replace: true });
              });
            } else {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }
          }
        });
      });
  }, [filteredWorks]);

  useEffect(() => {
    document.getElementById('gallery-link').addEventListener('click', () => {
      setSelectedCategories([]);
      setSelectedCollections([]);
      setIsCategoriesDropdownOpen(false);
      setIsCollectionsDropdownOpen(false);
    });

    const handleResize = () => {
      setIsMobileView(window.innerWidth < 500);
      if (window.innerWidth > 500) {
        setIsCategoriesDropdownOpen(false);
        setIsCollectionsDropdownOpen(false);
        setIsMobileFilterMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
  }, []);

  const filterButtons = () => {
    if (isMobileView) {
      return (
        <button
          type="button"
          className={`mobile-filter-button ${isMobileFilterMenuOpen ? 'active' : ''}`}
          onClick={
          () => setIsMobileFilterMenuOpen(!isMobileFilterMenuOpen)
        }
        >
          <Filter />
          <span>{locale === 'en' ? 'Filter' : 'フィルター'}</span>
        </button>
      );
    }

    return (
      <>
        <div className="dropdown">
          <button
            type="button"
            className={`dropdown-button ${isCategoriesDropdownOpen ? 'open' : ''}
                    ${params.getAll('category').length > 0 ? 'active' : ''}
                  `}
            onClick={() => {
              if (isCollectionsDropdownOpen) {
                setIsCollectionsDropdownOpen(false);
              }
              setIsCategoriesDropdownOpen(!isCategoriesDropdownOpen);
            }}
          >
            <span>{locale === 'en' ? 'Categories' : 'カテゴリー'}</span>
            <Caret />
          </button>
          <div className={`menu ${isCategoriesDropdownOpen ? 'open' : ''}`}>
            {categoryFilterMenu()}
          </div>
        </div>
        <div className="dropdown">
          <button
            type="button"
            className={`dropdown-button ${isCollectionsDropdownOpen ? 'open' : ''}
                  ${params.getAll('collection').length > 0 ? 'active' : ''}
                `}
            onClick={() => {
              if (isCategoriesDropdownOpen) {
                setIsCategoriesDropdownOpen(false);
              }
              setIsCollectionsDropdownOpen(!isCollectionsDropdownOpen);
            }}
          >
            <span>{locale === 'en' ? 'Collections' : 'コレクション'}</span>
            <Caret />
          </button>
          <div className={`menu ${isCollectionsDropdownOpen ? 'open' : ''}`}>
            {collectionsFilterMenu()}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <motion.div
        initial={false}
        animate={loaded ? 'in' : 'initial'}
        exit="out"
        variants={pageTransition.slideContentFromLeft.pageVariants}
        transition={pageTransition.slideContentFromLeft.transition}
        className="transition-container"
      >
        <div className="gallery-wrapper">
          <div className="title-wrapper">
            <h1>{ locale === 'en' ? 'GALLERY' : 'ギャラリー'}</h1>
            <div className="filter-buttons">
              {filterButtons()}
            </div>
            {currentCategoryParams.length > 0 || currentCollectionParams.length > 0 ? (
              <div className="tags">
                <div className="filter-tags">
                  {currentCategoryParams.map(category => (
                    <button
                      className="remove-single-filter-button"
                      onClick={removeFilter}
                      value={category}
                      type="button"
                    >
                      {locale === 'en' ? category : translations.filter.categories[category]}
                      <Close />
                    </button>
                  ))}
                  {currentCollectionParams.map(collection => (
                    <button
                      className="remove-single-filter-button"
                      onClick={removeFilter}
                      value={collection}
                      type="button"
                    >
                      {locale === 'en' ? collection : translations.filter.collections[collection]}
                      <Close />
                    </button>
                  ))}
                </div>
                <button
                  onClick={clearAll}
                  type="button"
                  className="clear-all-button"
                >
                  {locale === 'en' ? 'Clear All' : 'すべてクリア'}
                </button>
              </div>
            ) : ''}
          </div>
          {
            filteredWorks.length === 0 ? (
              <p className="no-works">No works found. Please change the filter and try again.</p>
            ) : ''
          }
          <div className="works-wrapper">
            { filteredWorks.map(work => (
              <Link
                to={`/${locale ? `${locale}/` : ''}gallery/${work.slug}`}
                className="work-card"
                id={work.slug}
                key={work.slug}
              >
                <div
                  style={{ backgroundImage: `url(${work.thumbnail.fields.file.url})` }}
                  className="thumbnail"
                  data-key={work.slug}
                />
                <div className="title-section">
                  <p>{ locale === 'en' ? work.title : work.titleJapanese}</p>
                  <p className="category-text">
                    {
                      locale === 'en'
                        ? work.category
                        : translations.filter.categories[work.category]
                    }
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </motion.div>
      <div className={`mobile-filter-menu ${isMobileFilterMenuOpen ? 'open' : ''}`}>
        <div className="mobile-header">
          {mobileFilterPopupHeader()}
        </div>
        {mobileFilterPopupMenuContent()}
      </div>
    </>
  );
};

export default Gallery;
