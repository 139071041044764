export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
    <path
      d="M3.399
      1.58963H0.55C0.404131
      1.58963
      0.264236
      1.64771
      0.161091
      1.7511C0.0579462
      1.85448
      0
      1.99471
      0
      2.14092C0
      2.28714
      0.0579462
      2.42736
      0.161091
      2.53075C0.264236
      2.63414
      0.404131
      2.69222
      0.55
      2.69222H3.399C3.51461
      3.0115
      3.7255
      3.28737
      4.00299
      3.48232C4.28049
      3.67726
      4.61113
      3.78184
      4.95
      3.78184C5.28887
      3.78184
      5.61951
      3.67726
      5.89701
      3.48232C6.1745
      3.28737
      6.38539
      3.0115
      6.501
      2.69222H10.45C10.5959
      2.69222
      10.7358
      2.63414
      10.8389
      2.53075C10.9421
      2.42736
      11
      2.28714
      11
      2.14092C11
      1.99471
      10.9421
      1.85448
      10.8389
      1.7511C10.7358
      1.64771
      10.5959
      1.58963
      10.45
      1.58963H6.501C6.38539
      1.27034
      6.1745
      0.994479
      5.89701
      0.79953C5.61951
      0.604581
      5.28887
      0.5
      4.95
      0.5C4.61113
      0.5
      4.28049
      0.604581
      4.00299
      0.79953C3.7255
      0.994479
      3.51461
      1.27034
      3.399
      1.58963ZM5.5
      2.14092C5.5
      2.24996
      5.46774
      2.35655
      5.40731
      2.44721C5.34687
      2.53787
      5.26098
      2.60853
      5.16048
      2.65026C5.05998
      2.69198
      4.94939
      2.7029
      4.8427
      2.68163C4.73601
      2.66035
      4.63801
      2.60785
      4.56109
      2.53075C4.48417
      2.45365
      4.43179
      2.35542
      4.41057
      2.24848C4.38935
      2.14153
      4.40024
      2.03069
      4.44187
      1.92995C4.48349
      1.82921
      4.55399
      1.74311
      4.64444
      1.68254C4.73488
      1.62196
      4.84122
      1.58963
      4.95
      1.58963C5.09587
      1.58963
      5.23576
      1.64771
      5.33891
      1.7511C5.44205
      1.85448
      5.5
      1.99471
      5.5
      2.14092ZM6.699
      5.4487H0.55C0.404131
      5.4487
      0.264236
      5.50679
      0.161091
      5.61017C0.0579462
      5.71356
      0
      5.85379
      0
      6C0
      6.14621
      0.0579462
      6.28644
      0.161091
      6.38983C0.264236
      6.49321
      0.404131
      6.5513
      0.55
      6.5513H6.699C6.81461
      6.87058
      7.0255
      7.14644
      7.30299
      7.34139C7.58049
      7.53634
      7.91113
      7.64092
      8.25
      7.64092C8.58887
      7.64092
      8.91951
      7.53634
      9.19701
      7.34139C9.4745
      7.14644
      9.68539
      6.87058
      9.801
      6.5513H10.45C10.5959
      6.5513
      10.7358
      6.49321
      10.8389
      6.38983C10.9421
      6.28644
      11
      6.14621
      11
      6C11
      5.85379
      10.9421
      5.71356
      10.8389
      5.61017C10.7358
      5.50679
      10.5959
      5.4487
      10.45
      5.4487H9.801C9.68539
      5.12942
      9.4745
      4.85356
      9.19701
      4.65861C8.91951
      4.46366
      8.58887
      4.35908
      8.25
      4.35908C7.91113
      4.35908
      7.58049
      4.46366
      7.30299
      4.65861C7.0255
      4.85356
      6.81461
      5.12942
      6.699
      5.4487ZM8.8
      6C8.8
      6.10904
      8.76774
      6.21562
      8.70731
      6.30628C8.64687
      6.39694
      8.56097
      6.46761
      8.46047
      6.50933C8.35998
      6.55106
      8.24939
      6.56198
      8.1427
      6.5407C8.03601
      6.51943
      7.93801
      6.46693
      7.86109
      6.38983C7.78417
      6.31273
      7.73179
      6.21449
      7.71057
      6.10755C7.68935
      6.00061
      7.70024
      5.88976
      7.74187
      5.78903C7.78349
      5.68829
      7.85399
      5.60219
      7.94444
      5.54161C8.03488
      5.48104
      8.14122
      5.4487
      8.25
      5.4487C8.39587
      5.4487
      8.53576
      5.50679
      8.63891
      5.61017C8.74205
      5.71356
      8.8
      5.85379
      8.8
      6ZM2.299
      9.30778H0.55C0.404131
      9.30778
      0.264236
      9.36586
      0.161091
      9.46925C0.0579462
      9.57264
      0
      9.71286
      0
      9.85908C0
      10.0053
      0.0579462
      10.1455
      0.161091
      10.2489C0.264236
      10.3523
      0.404131
      10.4104
      0.55
      10.4104H2.299C2.41461
      10.7297
      2.6255
      11.0055
      2.90299
      11.2005C3.18049
      11.3954
      3.51113
      11.5
      3.85
      11.5C4.18887
      11.5
      4.51951
      11.3954
      4.79701
      11.2005C5.0745
      11.0055
      5.28539
      10.7297
      5.401
      10.4104H10.45C10.5959
      10.4104
      10.7358
      10.3523
      10.8389
      10.2489C10.9421
      10.1455
      11
      10.0053
      11
      9.85908C11
      9.71286
      10.9421
      9.57264
      10.8389
      9.46925C10.7358
      9.36586
      10.5959
      9.30778
      10.45
      9.30778H5.401C5.28539
      8.9885
      5.0745
      8.71263
      4.79701
      8.51769C4.51951
      8.32274
      4.18887
      8.21816
      3.85
      8.21816C3.51113
      8.21816
      3.18049
      8.32274
      2.90299
      8.51769C2.6255
      8.71263
      2.41461
      8.9885
      2.299
      9.30778ZM4.4
      9.85908C4.4
      9.96811
      4.36774
      10.0747
      4.30731
      10.1654C4.24687
      10.256
      4.16098
      10.3267
      4.06048
      10.3684C3.95998
      10.4101
      3.84939
      10.4211
      3.7427
      10.3998C3.63601
      10.3785
      3.53801
      10.326
      3.46109
      10.2489C3.38417
      10.1718
      3.33179
      10.0736
      3.31057
      9.96663C3.28935
      9.85969
      3.30024
      9.74884
      3.34187
      9.64811C3.38349
      9.54737
      3.45399
      9.46127
      3.54444
      9.40069C3.63488
      9.34011
      3.74122
      9.30778
      3.85
      9.30778C3.99587
      9.30778
      4.13576
      9.36586
      4.23891
      9.46925C4.34205
      9.57264
      4.4
      9.71286
      4.4
      9.85908Z"
      fill="#E6E6E6"
    />
  </svg>
);
