export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 10" fill="none">
    <path
      d="M2.60515
        0.444191L8.50761
        6.33637L14.4101
        0.444191C15.0034
        -0.148064
        15.9617
        -0.148064
        16.555
        0.444191C17.1483
        1.03645
        17.1483
        1.99317
        16.555
        2.58542L9.57248
        9.55581C8.97919
        10.1481
        8.0208
        10.1481
        7.42752
        9.55581L0.444966
        2.58542C-0.148322
        1.99317
        -0.148322
        1.03645
        0.444966
        0.444191C1.03825
        -0.132878
        2.01186
        -0.148064
        2.60515
        0.444191Z
      "
      fill="#E6E6E6"
    />
  </svg>
);
