import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Header from './header/Header';
import '../stylesheets/application.scss';
import Top from './Top';
import AboutUs from './AboutUs';
import Gallery from './Gallery';
import Work from './work/Work';
import ContentfulProvider from './provider/ContentfulProvider';
import LocaleProvider from './provider/LocaleProvider';

const App = () => {
  const location = useLocation();

  return (
    <ContentfulProvider>
      <LocaleProvider>
        <Header location={location} />
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route exact path="/" element={<Top />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route exact path="/gallery" element={<Gallery location={location} />} />
            <Route path="/gallery/:slug" element={<Work />} />
            <Route exact path="/en" element={<Top />} />
            <Route path="/en/about-us" element={<AboutUs />} />
            <Route exact path="/en/gallery" element={<Gallery location={location} />} />
            <Route path="/en/gallery/:slug" element={<Work />} />
            <Route exact path="/jp" element={<Top />} />
            <Route path="/jp/about-us" element={<AboutUs />} />
            <Route exact path="/jp/gallery" element={<Gallery location={location} />} />
            <Route path="/jp/gallery/:slug" element={<Work />} />
          </Routes>
        </AnimatePresence>
      </LocaleProvider>
    </ContentfulProvider>
  );
};

export default App;
