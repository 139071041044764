import { useEffect, useState, useRef, useContext } from 'react';
import { motion } from 'framer-motion';
import SocialIcons from './elements/SocialIcons';
import { client, setInnerHtml } from '../helpers/contentful';
import { pageTransition } from '../helpers/animation';
import showHeader from '../helpers/header';
import LocaleContext from './context/LocaleContext';

export default () => {
  const descriptionSectionRef = useRef();
  const informationSection = useRef();
  const [loaded, setLoaded] = useState(false);
  const locale = useContext(LocaleContext);
  const contentLocale = locale.charAt(0).toUpperCase() + locale.slice(1);

  const pageTitle = {
    en: 'About Us',
    jp: '私たちについて'
  };

  useEffect(() => {
    client
      .getEntries({ content_type: 'company', limit: 1 }).then(response => {
        const company = response.items[0].fields;

        [
          {
            ref: descriptionSectionRef.current,
            data: company[`description${contentLocale}`]
          },
          {
            ref: informationSection.current,
            data: company[`informationTable${contentLocale}`]
          }
        ].forEach(content => {
          setInnerHtml(content.ref, content.data);
        });

        setLoaded(true);
        showHeader();
      });
  }, []);

  return (
    <motion.div
      className="about-wrapper"
      initial={{ opacity: 0 }}
      animate={loaded ? { opacity: 1 } : { opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="contents"
        initial={false}
        animate={loaded ? 'in' : 'initial'}
        exit="out"
        variants={pageTransition.slideContentFromBottom.pageVariants}
        transition={pageTransition.slideContentFromBottom.transition}
      >
        <div className="title-section">
          <motion.h1
            initial="initial"
            animate="animate"
          >
            {pageTitle[locale]}
          </motion.h1>
          <div className="description" ref={descriptionSectionRef} />
          <SocialIcons />
        </div>
        <div className="information-section" ref={informationSection} />
      </motion.div>
    </motion.div>
  );
};
