export const pageTransition = {
  slideContentFromLeft: {
    pageVariants: {
      initial: { opacity: 0, transform: 'translateX(-2%)' },
      in: { opacity: 1, transform: 'translateX(0)' },
      out: { opacity: 0, transform: 'translateX(-2%)' }
    },
    pageTransition: {
      type: 'tween',
      ease: [0.17, 0.67, 0.1, 1.05],
      duration: 1.8,
      delay: 0.1
    }
  },
  slideContentFromTop: {
    pageVariants: {
      initial: { opacity: 0, transform: 'translateY(2%)' },
      in: { opacity: 1, transform: 'translateY(0)' },
      out: { opacity: 0, transform: 'translateY(2%)' }
    },
    pageTransition: {
      type: 'tween',
      ease: [0.17, 0.67, 0.1, 1.05],
      duration: 1.8,
      delay: 0.1
    }
  },
  slideContentFromBottom: {
    pageVariants: {
      initial: { opacity: 0, transform: 'translateY(2%)' },
      in: { opacity: 1, transform: 'translateY(0)' },
      out: { opacity: 0, transform: 'translateY(-2%)' }
    },
    pageTransition: {
      type: 'tween',
      ease: [0.17, 0.67, 0.1, 1.05],
      duration: 4
    }
  }
};

const transition = { duration: 3, ease: [0.075, 0.82, 0.165, 1] };

export const workLetterVariation = {
  initial: { y: 400, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 2, ...transition }
  }
};
