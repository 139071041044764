// eslint-disable-next-line react/prop-types
const ArrowButton = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
  >
    <path
      d="M2 12H22M22 12L12.4 2M22 12L12.4 22"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowButton;
