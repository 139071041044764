import { Link } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const NavLinks = ({ isMobileMenuOpen, setIsMobileMenuOpen, navLinkPath, locale }) => {
  const links = {
    about: { path: `/${locale}/about-us`, text: locale === 'jp' ? '私たちについて' : 'ABOUT US' },
    gallery: { path: `/${locale}/gallery`, text: locale === 'jp' ? 'ギャラリー' : 'GALLERY' },
    blog: {
      path: 'https://note.com/yumechanhouse',
      text: locale === 'jp' ? 'ブログ' : 'BLOG'
    }
  };

  return (
    <ul>
      {Object.entries(links).map(([key, { path, text }]) => (
        <li key={key}>
          {key === 'blog' ? (
            <a href={path} target="_blank" rel="noreferrer">
              {text}
            </a>
          ) : (
            <Link
              to={path}
              onClick={() => (isMobileMenuOpen ? setIsMobileMenuOpen(false) : null)}
              id={key === 'gallery' ? 'gallery-link' : ''}
            >
              {text}
            </Link>
          )}
        </li>
      ))}
      <li className="language-switcher">
        <Link className={`${locale === 'en' ? 'active' : ''}`} to={navLinkPath('en')}>EN</Link>
        <div className="division" />
        <Link className={`${locale === 'jp' ? 'active' : ''}`} to={navLinkPath('jp')}>JP</Link>
      </li>
    </ul>
  );
};

NavLinks.defaultProps = {
  mobile: false,
  setOpen: () => {}
};

export default NavLinks;
