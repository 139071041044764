import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const contentful = require('contentful');

const isDevEnvironment = process.env.REACT_APP_DEV_ENVIRONMENT === 'development';

export const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: isDevEnvironment
    ? process.env.REACT_APP_DEV_ENV_CONTENTFUL_ACCESS_TOKEN
    : process.env.REACT_APP_PROD_ENV_CONTENTFUL_ACCESS_TOKEN,
  environment: isDevEnvironment
    ? process.env.REACT_APP_DEV_APPLICATION_ENVIRONMENT
    : process.env.REACT_APP_PROD_APPLICATION_ENVIRONMENT
});

export const setInnerHtml = (element, contents) => {
  if (element) element.innerHTML = documentToHtmlString(contents);
};
