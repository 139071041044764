import { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks';
import SocialIcons from '../elements/SocialIcons';
import LocaleContext from '../context/LocaleContext';

// eslint-disable-next-line no-unused-vars, react/prop-types
export default ({ location }) => {
  const mobileBreakpoint = 800;

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileBreakpoint);

  const locale = useContext(LocaleContext);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= mobileBreakpoint);
      if (window.innerWidth >= mobileBreakpoint) {
        setIsMobileMenuOpen(false);
      }
    });
  }, []);

  const navLinkPath = newLocale => {
    // Split the pathname to identify and update the locale part
    // eslint-disable-next-line react/prop-types
    const pathParts = location.pathname.split('/').filter(part => part);
    if (['en', 'jp'].includes(pathParts[0])) {
      pathParts[0] = newLocale; // Change the locale part
    } else {
      pathParts.unshift(newLocale); // Add the locale part if not present
    }

    // Preserve query parameters and hash fragment
    // eslint-disable-next-line react/prop-types
    const queryParams = location.search; // starts with '?' if present

    return `/${pathParts.join('/')}${queryParams}`;
  };

  return (
    <>
      <header>
        <Link
          id="logo"
          to={`/${locale}`}
          onClick={() => (isMobileMenuOpen ? setIsMobileMenuOpen(false) : '')}
        >
          Yumechan House
        </Link>
        <nav className={`${isMobile ? 'mobile-navbar' : 'desktop-navbar'}`}>
          {isMobile ? (
            <button
              className={`hamburger hamburger--spin ${isMobileMenuOpen ? 'is-active' : ''}`}
              type="button"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              onKeyUp={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          ) : <NavLinks navLinkPath={navLinkPath} locale={locale} />}
        </nav>
      </header>
      <div className={`hamburger-menu ${isMobileMenuOpen ? 'open' : ''}`}>
        <div className={`contents ${isMobileMenuOpen ? ' open' : ''}`}>
          <NavLinks
            isMobileMenuOpen
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            navLinkPath={navLinkPath}
            locale={locale}
          />
          <SocialIcons />
        </div>
      </div>
    </>
  );
};
