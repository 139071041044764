export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 12" fill="none">
    <path
      d="M0.689498
      0.500298C0.552979
      0.500334
      0.419572
      0.541094
      0.306333
      0.617367C0.193094
      0.69364
      0.105168
      0.80196
      0.0537969
      0.92848C0.00242576
      1.055
      -0.0100567
      1.19397
      0.0179453
      1.32762C0.0459473
      1.46127
      0.113161
      1.58354
      0.210995
      1.67878L4.52959
      5.99853L0.210995
      10.3183C0.145132
      10.3815
      0.0925491
      10.4573
      0.0563251
      10.5411C0.0201011
      10.625
      0.000964417
      10.7152
      3.55217e-05
      10.8065C-0.000893373
      10.8978
      0.0164044
      10.9884
      0.0509156
      11.073C0.0854269
      11.1575
      0.136458
      11.2343
      0.20102
      11.2989C0.265583
      11.3635
      0.342378
      11.4145
      0.426909
      11.4491C0.51144
      11.4836
      0.602008
      11.5009
      0.693308
      11.5C0.784608
      11.499
      0.874805
      11.4799
      0.958617
      11.4437C1.04243
      11.4074
      1.11817
      11.3548
      1.1814
      11.2889L5.5
      6.9692L9.81859
      11.2889C9.88183
      11.3548
      9.95757
      11.4074
      10.0414
      11.4437C10.1252
      11.4799
      10.2154
      11.499
      10.3067
      11.5C10.398
      11.5009
      10.4886
      11.4836
      10.5731
      11.4491C10.6576
      11.4145
      10.7344
      11.3635
      10.799
      11.2989C10.8635
      11.2343
      10.9146
      11.1575
      10.9491
      11.073C10.9836
      10.9884
      11.0009
      10.8978
      11
      10.8065C10.999
      10.7152
      10.9799
      10.625
      10.9437
      10.5411C10.9074
      10.4573
      10.8549
      10.3815
      10.789
      10.3183L6.47041
      5.99853L10.789
      1.67878C10.8882
      1.58236
      10.9559
      1.45822
      10.9833
      1.32264C11.0107
      1.18705
      10.9966
      1.04634
      10.9427
      0.918948C10.8888
      0.791554
      10.7977
      0.683419
      10.6813
      0.608715C10.5649
      0.53401
      10.4286
      0.496221
      10.2904
      0.500298C10.1121
      0.505612
      9.94289
      0.580145
      9.81859
      0.708108L5.5
      5.02786L1.1814
      0.708108C1.11745
      0.64235
      1.04097
      0.590081
      0.956485
      0.554389C0.871996
      0.518696
      0.781213
      0.500304
      0.689498
      0.500298Z"
      fill="#E6E6E6"
    />
  </svg>
);
