import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

const SocialIcons = () => (
  <div className="sns-wrapper">
    {Object.values({
      email: {
        icon: <EmailIcon />,
        path: 'mailto:welcome2cloudworld@gmail.com'
      },
      instagram: {
        icon: <InstagramIcon />,
        path: 'https://www.instagram.com/welcome2_cloud_world/'
      },
      twitter: {
        icon: <TwitterIcon />,
        path: 'https://twitter.com/Cloud_Syndrome'
      }
    }).map(social => (
      <a href={social.path} key={social.path} target="_blank" rel="noreferrer">
        {social.icon}
      </a>
    ))}
  </div>
);

export default SocialIcons;
