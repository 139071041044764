import { useEffect, useState, useRef, useContext } from 'react';
import { motion } from 'framer-motion';
import { useParams, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import imagesLoaded from 'imagesloaded';
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded
} from '@mui/icons-material';
import { setInnerHtml } from '../../helpers/contentful';
import { pageTransition } from '../../helpers/animation';
import { previousElement, nextElement } from '../../helpers/array';
import showHeader from '../../helpers/header';
import hasProperty from '../../helpers/object';
import ContentfulContext from '../context/ContentfulContext';
import LocaleContext from '../context/LocaleContext';
import ArrowButton from '../ArrowButton';
import translations from '../../helpers/translations.json';

const Work = () => {
  const [loaded, setLoaded] = useState(false);
  const [currentWork, setCurrentWork] = useState({});
  const [neighboringWorks, setNeighboringWorks] = useState(
    {
      previousWork: { title: '', slug: '' },
      nextWork: { title: '', slug: '' }
    }
  );

  const allWorks = useContext(ContentfulContext);

  const descriptionRef = useRef();
  const workContainerRef = useRef();
  const zoomScreenRef = useRef();
  const zoomedImageRef = useRef();
  const infoWrapperRef = useRef();

  const { slug } = useParams();

  const toggleZoomScreen = event => {
    if (event.target.nodeName === 'IMG') {
      zoomScreenRef.current.style.opacity = 1;
      zoomScreenRef.current.style.visibility = 'visible';
      infoWrapperRef.current.classList.add('disabled');
      workContainerRef.current.classList.add('disabled');
      zoomedImageRef.current.src = event.target.src;
      document.querySelector('header').style.zIndex = '0';
    } else {
      zoomScreenRef.current.style.opacity = 0;
      zoomScreenRef.current.style.visibility = 'hidden';
      infoWrapperRef.current.classList.remove('disabled');
      workContainerRef.current.classList.remove('disabled');
      document.querySelector('header').style.zIndex = '99';
    }
  };

  const format = price => (price.length < 3 ? price : price.toLocaleString());

  const displayPrice = () => {
    if (currentWork.priceValue) return `${format(currentWork.priceValue)} JPY`;

    return locale === 'en' ? currentWork.price : '見積もり';
  };

  const emailSubject = () => (
    locale === 'en' ? `Regarding ${currentWork.title}` : `${currentWork.titleJapanese}について`
  );

  const locale = useContext(LocaleContext);

  useEffect(() => {
    if (allWorks.length > 0) {
      const matchedWork = allWorks.find(item => item.slug === slug);

      setCurrentWork(matchedWork);

      const nextWork = nextElement(allWorks, matchedWork);
      const previousWork = previousElement(allWorks, matchedWork);

      setNeighboringWorks({
        nextWork: { title: nextWork.title, slug: nextWork.slug },
        previousWork: { title: previousWork.title, slug: previousWork.slug }
      });
    }
  }, [allWorks]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Object.keys(currentWork).length > 0) {
      if (descriptionRef.current) {
        if (locale === 'en') {
          setInnerHtml(descriptionRef.current, currentWork.description);
        } else {
          setInnerHtml(descriptionRef.current, currentWork.descriptionJapanese);
        }
      }

      imagesLoaded(workContainerRef.current, () => {
        setLoaded(true);
        showHeader();
      });
    }
  }, [currentWork]);

  return (
    Object.keys(currentWork).length > 0 ? (
      <motion.div
        className="work-container"
        ref={workContainerRef}
        initial={false}
        animate={loaded ? 'in' : 'initial'}
        exit="out"
        variants={pageTransition.slideContentFromBottom.pageVariants}
        transition={pageTransition.slideContentFromBottom.transition}
      >
        <div className="image-wrapper">
          <div className="content">
            {hasProperty(currentWork, 'images') ? (
              <>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  loop
                  pagination={{
                    clickable: true,
                    el: '.pagination-button-container'
                  }}
                  navigation={{
                    prevEl: '.previous-button',
                    nextEl: '.next-button'
                  }}
                  className="mySwiper"
                  modules={[Navigation, Pagination]}
                >
                  <SwiperSlide>
                    <motion.img
                      src={currentWork.thumbnail.fields.file.url}
                      alt="thumbnail"
                      key={currentWork.thumbnail}
                      onClick={toggleZoomScreen}
                    />
                  </SwiperSlide>
                  {currentWork.images.map(image => (
                    <SwiperSlide key={image.fields.file.url}>
                      <motion.img
                        src={image.fields.file.url}
                        alt="thumbnail"
                        key={currentWork.thumbnail}
                        onClick={toggleZoomScreen}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="pagination-button-container" />
                <div className="navigation-buttons">
                  <ArrowButton className="previous-button" />
                  <ArrowButton className="next-button" />
                </div>
              </>
            ) : (
              <div className="single-image-container">
                <motion.img
                  src={currentWork.thumbnail.fields.file.url}
                  alt="thumbnail"
                  key={currentWork.thumbnail}
                  onClick={toggleZoomScreen}
                />
              </div>
            )}
          </div>
        </div>
        <div className="info-wrapper" ref={infoWrapperRef}>
          <div className="contents">
            <div className="title-wrapper">
              <Link
                to={`/${locale ? `${locale}/` : ''}gallery`}
                state={{ workId: currentWork.slug }}
                className="back-to-gallery-link"
              >
                <ChevronLeftRounded />
                <p>{locale === 'en' ? 'Back to Gallery' : 'ギャラリーへ戻る'}</p>
              </Link>
              <motion.h1
                initial="initial"
                animate="animate"
              >
                {locale === 'en' ? currentWork.title : currentWork.titleJapanese}
              </motion.h1>
            </div>
            {
              hasProperty(currentWork, 'description') ? (
                <div className="description" ref={descriptionRef} />
              ) : ''
            }
            {
              currentWork.category === 'Apparel' ? '' : (
                <div className="attributes">
                  <div className="attribute">
                    <p>
                      <span>{locale === 'en' ? 'Date:' : '制作年:'}</span>
                      <span>{currentWork.year}</span>
                    </p>
                  </div>
                  {
                    hasProperty(currentWork, 'width') && hasProperty(currentWork, 'height')
                      ? (
                        <div className="attribute">
                          <p>
                            <span>{locale === 'en' ? 'Size:' : 'サイズ:'}</span>
                            <span>{`${currentWork.width}mm x ${currentWork.height}mm`}</span>
                          </p>
                        </div>
                      ) : ''
                  }
                  <div className="attribute">
                    <p>
                      <span>{locale === 'en' ? 'Medium:' : '媒体:'}</span>
                      <span>
                        {
                          locale === 'en'
                            ? currentWork.medium
                            : translations.medium[currentWork.medium]
                        }
                      </span>
                    </p>
                  </div>
                  <div className="attribute">
                    <p>
                      <span>{locale === 'en' ? 'Availability:' : '在庫:'}</span>
                      <span>
                        {
                          locale === 'en'
                            ? currentWork.availability
                            : translations.availability[currentWork.availability]
                        }
                      </span>
                    </p>
                  </div>
                </div>
              )
            }
            {hasProperty(currentWork, 'price') && currentWork.price !== 'N/A' ? (
              <div className="price-wrapper">
                <p>{locale === 'en' ? 'Price' : '価格'}</p>
                <h2>
                  {displayPrice()}
                </h2>
              </div>
            ) : ''}
            <div className="button-container">
              <a
                href={`mailto:welcome2cloudworld@gmail.com?subject=${emailSubject()}`}
                className="cta-button"
              >
                <p>{locale === 'en' ? 'Inquire' : '作品について問い合わせる'}</p>
              </a>
            </div>
            <div className="neighboring-works-link-wrapper">
              <Link
                to={`/${locale ? `${locale}/` : ''}gallery/${neighboringWorks.previousWork.slug}`}
                className="work-link"
                id="previous-work-link"
              >
                <ChevronLeftRounded />
                <p>{locale === 'en' ? 'Previous Work' : '前の作品へ'}</p>
              </Link>
              <Link
                to={`/${locale ? `${locale}/` : ''}gallery/${neighboringWorks.nextWork.slug}`}
                className="work-link"
                id="next-work-link"
              >
                <p>{locale === 'en' ? 'Next Work' : '次の作品へ'}</p>
                <ChevronRightRounded />
              </Link>
            </div>
          </div>
        </div>
        <div
          className="zoom-screen"
          ref={zoomScreenRef}
          onClick={toggleZoomScreen}
          onKeyDown={toggleZoomScreen}
          role="presentation"
        >
          <CloseRounded onClick={toggleZoomScreen} />
          <img src="" alt="zoomed" ref={zoomedImageRef} />
        </div>
      </motion.div>
    ) : null
  );
};

export default Work;
