export const previousElement = (wholeList, elementOfInterest) => {
  const index = wholeList.indexOf(elementOfInterest);

  if (index === -1) return {};
  if (index === 0) return wholeList[wholeList.length - 1];

  return wholeList[index - 1];
};

export const nextElement = (wholeList, elementOfInterest) => {
  const index = wholeList.indexOf(elementOfInterest);

  if (index === -1) return {};
  if (index === wholeList.length - 1) return wholeList[0];

  return wholeList[index + 1];
};
